export const DateTimeFunc = {
    DAYS: ()=>{
        let days = [];
        for(let i = 1; i <=31; i++){
            days.push(("0"+i).substring(("0"+i).length - 2));
        }
        return days
    },
    MONTH: ()=>{
        return [
             {"key":"01","txt": "มกราคม"},
             {"key":"02", "txt": "กุมภาพันธุ์"},
             {"key":"03", "txt": "มีนาคม"},
             {"key":"04", "txt": "เมษายน"},
             {"key":"05", "txt": "พฤษภาคม"},
             {"key":"06", "txt": "มิถุนายน"},
             {"key":"07", "txt": "กรกฎาคม"},
             {"key":"08", "txt": "สิงหาคม"},
             {"key":"09", "txt": "กันยายน"},
             {"key":"10", "txt": "ตุลาคม"},
             {"key":"11", "txt": "พฤศจิกายน"},
             {"key":"12", "txt": "ธันวาคม"}
        ];

    },


    YEAR: ()=>{
        let start = new Date().getFullYear() - 70;
        let end = new Date().getFullYear();
        let year = [];
        for(let i=start;i < end-10; i++){
            year.push(i);
        }

        return year;

    },


    SUM_ARRAY: (items, prop)=>{
        return items.reduce( function(a,
         b){
                    return a + b[prop];
                },
         0);
       },





    TH_MONTH:  (month)=>{
        let ret = ""
        switch (month) {
            case "01":
                ret = "มกราคม";
                break;
            case "02":
                ret = "กุมภาพันธ์ุ";
                break;
            case "03":
                ret = "มีนาคม";
                break;
            case "04":
                ret = "เมษายน";
                break;
            case "05":
                ret = "พฤษภาคม";
                break;
            case "06":
                ret = "มิถุนายน";
                break;
            case "07":
                ret = "กรกฎาคม";
                break;
            case "08":
                ret = "สิงหาคม";
                break;
            case "09":
                ret = "กันยายน";
                break;
            case "10":
                ret = "ตุลาคม";
                break;
            case "11":
                ret = "พฤศจิกายน";
                break;
            case "12":
                ret = "ธันวาคม";
                break;
            default: break;


        }
        return ret
    },



    reformatDate(idate){
        let dt = idate.split("T");
        let d = dt[0].split("-");
        let t = dt[1].substring(0,
 8);

        return d[2]+' '+this.TH_MONTH(d[1])+' '+(parseInt(d[0])+543)+ ' | '+t;

    }
    // "01": "มกราคม",

    //     "02": "กุมภาพันธ์ุ",

    //         "03": "มีนาคม",

    //             "04": "เมษายน",

    //                 "05": "พฤษภาคม",

    //                     "06": "มิถุนายน",

    //                         "07": "กรกฎาคม",

    //                             "08": "สิงหาคม",

    //                                 "09": "กันยายน",

    //                                     "10": "ตุลาคม",

    //                                         "11": "พฤศจิกายน",

    //                                             "12": "ธันวาคม",

}
